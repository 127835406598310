// jsスクロールインアニメーション
.js-in {
  opacity: 0;
	animation-delay: -2s;
  transition: all .8s ease-in-out;
  &.is-active {
    opacity: 1 !important;
  }

  &--up {
    transform: translateY(15%);
    &.is-active {
      transform: translateY(0%);
    }
  }

  &--right {
    transform: translate3d(100%, 0, 0);
    &.is-active {
      transform: translate3d(0, 0, 0);
    }
  }

  &--left {
    transform: translate3d(-100%, 0, 0);
    transition: all 1s ease-in;
    &.is-active {
      transform: translate3d(0, 0, 0);
    }
  }
}