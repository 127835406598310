.u-weight {
  &--bold {
    font-weight: bold;
  }
}
.u-font {
  &--kiwi {
    font-family: $font-kiwi;
  }
}
.u-fsize {
  &--20px {
    font-size: 20px !important;
  }
  &--32px {
    font-size: 32px !important;
  }
  &--48px {
    font-size: 48px !important;
  }
  &--lg--16px {
    @include mq-down(lg) {
      font-size: 16px !important;
    }
  }
  &--lg--24px {
    @include mq-down(lg) {
      font-size: 24px !important;
    }
  }
}
.u-shadow {
  text-shadow: 0 3px 6px rgba(0,0,0,0.16);
}
.u-color {
  &--primary {
    color: $primary;
  }
  &--white {
    color: $white;
  }
  &--black{
    color: $text-color;
  }
}
.u-list {
  &--none {
    list-style: none;
  }
}
.u-img {
  &--w100 {
    width: 100%;
    height: auto;
  }
}
.u-width {
  &--964px {
    max-width: 964px;
    margin-left: auto;
    margin-right: auto;
  }
  &--760px {
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
  }
}
