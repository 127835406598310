.l-footer {

}

footer {

}

.foot-copy {
    text-align: center;
    background: #fff;
    padding: 0.8em;
    margin: 0;
    font-size: .9rem;

    @include mq-up(md) {
        font-size: 1rem;
    }
}

.pc-foot {
    background-color: #E0EED2;
    border-top: 20px solid #6EB123;
    padding: 64px 0;

    .foot-company-logo {
        width: 330px;
        margin-bottom: 2em;
    }

    .inner {
        border-left: 1px solid #727171;
        padding-left: 74px;

        li {
            margin-bottom: 1em;

            &::before {
                content: "●";
                display: inline-block;
                color: #F0A000;
                margin-right: .5em;
            }

            a {
                color: #727171;
            }
        }


    }

    .contact-mail {
        display: block;
        width: 116px;
    }

    .foot-insta {
        display: block;
        width: 152px;
    }
}

.sp-foot {

    nav {
        margin-top: 80px;

        li {

            a {
                background-color: #6EB123;
                color: #fff;
                border-bottom: 1px solid #fff;
                display: block;
                text-align: center;
                padding: 0.8em 0;
            }
        }
    }

    .foot-insta-sp {
        display: block;
        width: 96px;
        margin: 20px auto;
    }

    .sp-foot-info {
        background-color: #F1F1F1;
        padding: 48px 24px;

        a,
        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .contact-logo {
        width: 256px;
        display: block;
        margin-bottom: 40px;
    
        @include mq-up(md) {
            width: 330px;
            margin-bottom: 40px;
        }
    }
    .contact-mail {
        width: 116px;
        display: block;
        margin-bottom: 20px;
    }
    .contact-tel {
        width: 224px;
        display: block;
        margin-bottom: 20px;
    
        @include mq-up(md) {
            width: 300px;
        }
    }
    .contact-time {
        width: 226px;
        display: block;
    
        @include mq-up(md) {
            width: 300px;
        }
    }
}

.pc-link-icon {
    height: 16px;
    margin-left: 5px;
    margin-bottom: 2px;
}
