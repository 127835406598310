@include mq-down(lg) {
  .container {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  .row {
    margin-right: -12px !important;
    margin-left: -12px !important;
  }
  .row>* {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
}