
.front-page.branding {
    position: relative;
    background-image: url(../../image/fv-sp-bg@2x-8.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    overflow: hidden;
    //height: 94vh;
    padding-bottom: 140%;

    @include mq-up(lg) {
        background-image: url(../../image/bg-maru@2x-8.png);
        background-position: center;
        height: auto;
        padding-bottom: 0;
        background-size: cover;
    }
}

.first-view {

    .inner {
        max-width: 100%;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        display: none;

        @include mq-up(lg) {
            max-width: 1200px;
            display: block;
        }

        .inner-cont {
            width: 520px;
            position: relative;
            z-index: 2;
            margin-top: 170px;
            margin-left: 56px;
            margin-bottom: 78px;

            .inner-contact {
                display: flex;
                align-items: center;
                margin-top: 120px;

                .fv-tel {
                    width: 300px;
                    margin-right: 12px;
                }

                .fv-mail {
                    width: 116px;
                }
            }
        }
    }
}

img.fv-hito {
    display: none;

    @include mq-up(lg) {
        display: block;
        position: absolute;
        top: -150px;
        left: 46%;
        width: 925px;
        z-index: -1;
    }
}

img.fv-logo {
    position: absolute;
    left: 0;
    width: 700px;
}

.catalog-p {
    color: #FFEE00;
    text-align: center;
    line-height: 2.2;
    margin: 40px auto;
}

.catalog-btn {
    width: 40%;
    margin: 0px auto;
}

.catalog-btn {
    width: 40%;
    margin: 0px auto;
}


@media only screen and (max-width: 480px) {
    .catalog-btn {
        width: 56%;
    }
}