.l-header {

}

.sp-link {
    height: 37px !important;
}

.header {
    .container{
        width: 1240px;
    }
}