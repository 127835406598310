#recruit,
.p-recruit {
  //共通設定
  .overflow-outer {
    overflow: hidden;
  }
  .float-item {
    position: absolute;
  }
  .p-rel {
    position: relative;
  }
  &--info {
    margin-bottom: 80px;
    @include mq-down(xl) {
      margin-bottom: 40px;
    }
    .img-b {
      margin: 0px auto;
      margin-top: 5rem;
      margin-bottom: 50px;

      @include mq-down(xl) {
        margin-top: 60px;
        margin-bottom: 40px;
      }
      img {
        width: 375px;
        @include mq-down(xl) {
          width: 292px;
        }
      }
    }
  }

  // 事業内容
  &--content {
    background: #fbf3e7;
    position: relative;
    padding: 100px 0 60px;

    @include mq-down(xl) {
      padding: 40px 0 20px;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block;
      z-index: 0;
      width: 100vw;
      height: 150px;
      background: url(../image/recruit/recruit-hanen-2-2x.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: top center;
      @include mq-down(xl) {
        height: 40px;
        background: url(../image/recruit/recruit-hanen-2-sp-2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: top center;
      }
    }
    .head-title {
      margin-bottom: 50px;
      position: relative;
      z-index: 1;
      @include mq-down(xl) {
        margin-bottom: 20px;
      }
      img {
        width: 128px;
        @include mq-down(xl) {
          width: 88px;
        }
      }
    }

    .text {
      p {
        position: relative;
        z-index: 10;
      }
    }

    //アイテム
    .item-01 {
      bottom: -160px;
      left: -240px;
      z-index: 1;
      width: 383px;
      height: 383px;
      @include mq-down(xl) {
        display: none;
      }
    }
  }
  // 当社の特徴
  &--feature {
    background: #f1a100;
    position: relative;
    padding: 70px 0 60px;
    @include mq-down(xl) {
      padding: 40px 0 240px;
    }

    .head-title {
      margin-bottom: 20px;
      img {
        width: 154px;
        @include mq-down(xl) {
          width: 108px;
        }
      }
    }
    .text {
      p {
        color: #fff;
        position: relative;
        z-index: 10;
      }
    }
    //画像
    .item {
      margin: 20px 0 0;
      img {
        width: 488px;
        @include mq-down(xl) {
          width: 296px;
        }
      }
    }
    //アイテム
    .item-01 {
      top: -100px;
      right: -200px;
      z-index: 1;
      @include mq-down(xl) {
        top: inherit;
        bottom: -200px;
        right: -140px;
      }
      .main {
        width: 275px;
        height: 275px;
        position: relative;
        z-index: 2;
        @include mq-down(xl) {
          width: 170px;
          height: 170px;
        }
      }
      .sub {
        width: 342px;
        height: 342px;
        margin-left: -60px;
        margin-bottom: -160px;
        @include mq-down(xl) {
          width: 219px;
          height: 219px;
        }
      }
    }
    .item-02 {
      top: 200px;
      left: -60px;
      z-index: 1;
      @include mq-down(xl) {
        top: inherit;
        bottom: -220px;
        left: -160px;
      }
      .main {
        width: 228px;
        height: 228px;
        position: relative;
        z-index: 2;
        @include mq-down(xl) {
          width: 145px;
          height: 145px;
        }
      }
      .sub {
        width: 242px;
        height: 242px;
        margin: -200px -140px 0 0;
        @include mq-down(xl) {
          width: 219px;
          height: 219px;
          margin: 0px -60px -140px 0;
        }
      }
    }
  }

  // 当社の魅力
  &--charm {
    background: #6fb62c;
    position: relative;
    padding: 70px 0 40px;
    @include mq-down(xl) {
      padding: 40px 0 260px;
    }

    .head-title {
      margin-bottom: 40px;
      @include mq-down(xl) {
        margin-bottom: 20px;
      }
      img {
        width: 154px;
        @include mq-down(xl) {
          width: 108px;
        }
      }
    }
    .text {
      margin-bottom: 40px;
      @include mq-down(xl) {
        margin-bottom: 20px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
      .title {
        text-align: center;
        font-size: 20px;
        color: #ffee00;
        padding: 0;
        margin-bottom: 8px;
        @include mq-down(xl) {
          font-size: 12px;
        }
      }
      p {
        color: #fff;
        position: relative;
        z-index: 10;
      }
    }
    //画像
    .item {
      margin: 60px 0 20px;
      @include mq-down(xl) {
        margin: 20px 0 30px;
      }

      &__inner {
        position: relative;
        display: inline-block;
      }

      .title {
        position: absolute;
        top: -30px;
        left: 0;
        border-bottom: none;
        padding: 0;
        margin: 0;
        color: #fff;
        text-align: left;
        width: fit-content;
        font-size: 16px;
        @include mq-down(xl) {
          position: relative;
          text-align: center;
          top: inherit;
          left: inherit;
          margin: 0 auto 10px;
          font-size: 12px;
        }
      }
      img {
        width: 488px;
        @include mq-down(xl) {
          width: 119px;
        }
      }
    }
    //アイテム
    .item-01 {
      top: -100px;
      right: -20px;
      z-index: 1;
      @include mq-down(xl) {
        top: inherit;
        bottom: -260px;
        right: -130px;
      }
      .main {
        width: 251px;
        height: 251px;
        position: relative;
        z-index: 2;
        @include mq-down(xl) {
          width: 166px;
          height: 166px;
        }
      }
      .sub {
        width: 254px;
        height: 254px;
        margin-left: -140px;
        margin-top: -160px;
        @include mq-down(xl) {
          width: 219px;
          height: 219px;
          margin-top: inherit;
          margin-left: -60px;
          margin-bottom: -160px;
        }
      }
    }
    .item-02 {
      top: 400px;
      left: -240px;
      z-index: 1;
      @include mq-down(xl) {
        top: inherit;
        left: -120px;
        bottom: -180px;
      }
      .main {
        width: 234px;
        height: 234px;
        position: relative;
        z-index: 2;
        @include mq-down(xl) {
          width: 144px;
          height: 144px;
        }
      }
      .sub {
        width: 314px;
        height: 314px;
        margin: -200px -140px 0 0;
        @include mq-down(xl) {
          width: 219px;
          height: 219px;
          margin: 0px -80px -60px 0;
        }
      }
    }
    .item-03 {
      bottom: -168px;
      right: -300px;
      z-index: 1;
      @include mq-down(xl) {
        display: none;
      }
      img {
        width: 383px;
        height: 383px;
      }
    }
  }

  // 求職者の方へ
  &--message {
    background: #fbf3e7;
    position: relative;
    padding: 60px 0 80px;
    @include mq-down(xl) {
      padding: 40px 0 120px;
    }

    &__inner {
      background: url(../image/recruit/jigyou_bg@2x.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      padding: 60px 0 20px;
      @include mq-down(xl) {
        background: url(../image/recruit/jigyou_bg__sp@2x.png);
        padding: 40px 40px 80px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 96%;
        margin: 0 auto;
      }
    }

    .head-title {
      margin-bottom: 20px;
      @include mq-down(xl) {
        margin-bottom: 20px;
      }
      img {
        width: 304px;
        @include mq-down(xl) {
          width: 210px;
        }
      }
    }
    .text {
      margin-bottom: 40px;
      @include mq-down(xl) {
        margin-bottom: 20px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
      .title {
        text-align: center;
        font-size: 20px;
        color: #ffee00;
        padding: 0;
        margin-bottom: 8px;
        @include mq-down(xl) {
          font-size: 12px;
        }
      }
      p {
        position: relative;
        z-index: 10;
        @include mq-down(xl) {
          line-height: 1.8 !important;
        }
      }
    }
    //アイテム
    .item-01 {
      top: 20px;
      right: -170px;
      z-index: 1;
      @include mq-down(xl) {
        display: none;
      }
      img {
        width: 210px;
        height: 210px;
      }
    }
    .item-02 {
      top: 280px;
      right: -230px;
      z-index: 1;
      @include mq-down(xl) {
        display: none;
      }
      img {
        width: 146px;
        height: 146px;
      }
    }
    .item-03 {
      top: 40px;
      left: -160px;
      z-index: 1;
      @include mq-down(xl) {
        display: none;
      }
      img {
        width: 280px;
        height: 280px;
      }
    }
    .item-04 {
      top: 290px;
      left: -160px;
      z-index: 1;
      @include mq-down(xl) {
        top: inherit;
        bottom: -80px;
        left: -15px;
      }
      img {
        width: 510px;
        height: auto;
        @include mq-down(xl) {
          width: 274px;
        }
      }
    }
  }
  // 募集内容
  &--detail {
    padding: 0px 0px 0px;
    .point {
      width: 73%;
      margin: 0 auto 80px;
      @include mq-down(xl) {
        width: calc(100% - 40px);
        margin: 0 auto 60px;
      }

      .title {
        border-bottom: none !important;
        padding: 0;
        margin: 0 auto 12px !important;
        text-align: center !important;
        img {
          width: 205px;
          @include mq-down(xl) {
            width: 140px;
          }
        }
      }

      &__box {
        padding: 30px 24px;
        @include mq-down(xl) {
          padding: 24px 24px;
        }
        border-radius: 12px;
        background: url(../image/recruit/line-bg@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        @include mq-down(xl) {
          background: url(../image/recruit/line-bg--sp@2x.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center;
        }

        hr {
          opacity: 1 !important;
          margin: 20px auto;
          @include mq-down(xl) {
            margin: 12px auto;
          }
        }

        .top {
          font-size: 16px !important;
          text-align: left !important;
          @include mq-down(xl) {
            font-size: 13px !important;
            line-height: 1.6;
          }
        }
        .bottom {
          font-size: 15px !important;
          @include mq-down(xl) {
            font-size: 12px !important;
            line-height: 1.6;
          }
        }
        p {
          margin: 0;
        }
      }
    }
  }

  // Google Form
  &--form {
    padding: 100px 0 40px;
    @include mq-down(xl) {
      padding: 40px 0 0;
      margin: 0 0 -60px;
    }
    iframe {
      @include mq-down(xl) {
        height: 2060px;
      }
    }
  }
  .img-b4 {
    width: 20%;
    margin: 0px auto;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .r-s {
    position: absolute; /*重ねたい子要素にabsolute*/
    top: 35% !important;
    color: #fff;
    font-size: 15px;
    left: 50%;
    -ms-transform: translate(-50%, -50%); /*ベンダープレフィックス*/
    -webkit-transform: translate(-50%, -50%); /*ベンダープレフィックス*/
    transform: translate(-50%, -50%); /*センター寄せの修正*/
    margin: 0 !important; /*文字がずれている場合や*/
    padding: 0 !important; /*文字が折り返される場合*/
    line-height: 2.2 !important;
  }

  .r-s-2 {
    position: absolute; /*重ねたい子要素にabsolute*/
    top: 63% !important;
    color: #fff;
    font-size: 15px;
    left: 50%;
    -ms-transform: translate(-50%, -50%); /*ベンダープレフィックス*/
    -webkit-transform: translate(-50%, -50%); /*ベンダープレフィックス*/
    transform: translate(-50%, -50%); /*センター寄せの修正*/
    margin: 0 !important; /*文字がずれている場合や*/
    padding: 0 !important; /*文字が折り返される場合*/
    line-height: 2.2 !important;
  }

  .r-s-3 {
    position: absolute; /*重ねたい子要素にabsolute*/
    top: 73% !important;
    color: #fff;
    font-size: 15px;
    left: 50%;
    -ms-transform: translate(-50%, -50%); /*ベンダープレフィックス*/
    -webkit-transform: translate(-50%, -50%); /*ベンダープレフィックス*/
    transform: translate(-50%, -50%); /*センター寄せの修正*/
    margin: 0 !important; /*文字がずれている場合や*/
    padding: 0 !important; /*文字が折り返される場合*/
    line-height: 2.2 !important;
  }

  .r-s-4 {
    position: absolute; /*重ねたい子要素にabsolute*/
    top: 88% !important;
    color: #fff;
    font-size: 15px;
    left: 50%;
    -ms-transform: translate(-50%, -50%); /*ベンダープレフィックス*/
    -webkit-transform: translate(-50%, -50%); /*ベンダープレフィックス*/
    transform: translate(-50%, -50%); /*センター寄せの修正*/
    margin: 0 !important; /*文字がずれている場合や*/
    padding: 0 !important; /*文字が折り返される場合*/
    line-height: 2.2 !important;
  }

  .flexbox {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    width: 50%;
    margin: 0px auto;
    margin-bottom: 50px;
  }

  .flexbox-2 {
    display: -webkit-flex;
    display: flex;
    width: 80%;
    margin: 0px auto;
    margin-left: 60px;
    margin-bottom: 50px;
    justify-content: center;
    align-items: center;
    @include mq-down(xl) {
      width: 90%;
      margin: 0 auto 20px;
    }
  }

  .syokusyu {
    margin: 0 10px;
    -webkit-flex: 1;
    flex: 1;
  }
  .sales {
    @include mq-up(xl) {
      margin: 0 20px;
      -webkit-flex: 2;
      flex: 2;
    }
  }
  .jimu {
    @include mq-up(xl) {
      -webkit-flex: 3;
      flex: 3;
    }
    img {
      width: 55%;
    }
  }

  p {
    text-align: center;

    &.gyoukan-p {
      text-align: left;
      @include mq-down(xl) {
        line-height: 2.2;
      }

      @include mq-up(sm) {
        text-align: center;
      }
    }
  }
  h4 {
    margin: 0;
    font-size: 24px;
    border-bottom: solid 2px #f1a100;
    padding-bottom: 5px;
    margin-bottom: 15px;
    text-align: left;
    font-weight: bold;
    color: #f1a100;
    width: 70%;
    margin: 0px auto;
    margin-top: 5rem;
  }

  h5 {
    color: #ffee00;
    font-size: 20px;
  }

  h6 {
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: solid 1px;
  }

  .p-ml {
    margin-left: 15px;
    text-align: left !important;
    @include mq-down(xl) {
      margin-left: 0;
    }
  }

  #table01 {
    margin-bottom: 3rem;
    @include mq-down(xl) {
      margin-bottom: 24px;
    }
    tr {
      border-bottom: 4px dotted #6fb62c;
    }
    th {
      @include mq-down(xl) {
        padding: 24px 0 12px;
      }
    }
    th,
    td {
      @include mq-down(xl) {
        font-size: 13px;
      }
    }
  }

  .appeal {
    width: 74%;
    margin: 0px auto;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .table-icon {
    display: flex;
    @include mq-down(xl) {
      font-weight: 700;
      font-size: 16px;
      align-items: center;
    }
    img {
      width: 5%;
      height: 5%;
      margin-right: 10px;
      @include mq-down(xl) {
        margin-top: 3px;
      }
    }
  }
  .hr-line {
    border: 2px solid #6fb62c;
    margin: 0px auto;
    margin-bottom: 50px;
    width: 73%;
  }

  .mikeiken-ok {
    background-color: #868686;
    padding-left: 10px;
    padding-right: 10px;
    color: #fff;
    margin-left: auto;
  }
}

.sp-none {
  display: block !important;
}

.pc-none {
  display: none !important;
}

.tb-none {
  display: block !important;
}

.text-l {
  text-align: left !important;
}

.custom-shape-divider-bottom-1713336324 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1713336324 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 262px;
}

.custom-shape-divider-bottom-1713336324 .shape-fill {
  fill: #fbf3e7;
}

.frame-center {
  iframe {
    margin: 0px auto;
    display: block;
    margin-bottom: 0px;
    width: 100%;
  }
}

/* sp */
@include mq-down(xl) {
  #recruit p {
    font-size: 13px !important;
  }
  h4 {
    width: 100% !important;
  }
  h6 {
    font-size: 13px !important;
  }
  .img-b2 {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .d-flex {
    display: block !important;
  }

  .sp-none {
    display: none !important;
  }

  .pc-none {
    display: flex !important;
  }

  #recruit .syokusyu {
    margin: 50px 10px;
    text-align: center;
  }

  #recruit .jimu img {
    width: 80%;
  }

  #recruit .sales img {
    width: 80%;
  }

  #recruit .table-icon img {
    width: 7%;
    margin-right: 10px;
  }

  #recruit .hr-line {
    border: 1px solid #6fb62c;
    margin: 50px auto;
    margin-bottom: 20px;
    width: 100%;
  }
}

.adjust-p {
  @include mq-up(sm) {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}
