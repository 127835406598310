
.c-bg {
  @include bg-image-set('../image/bg-washi.png');
    background-size: 100%;
    background-repeat: repeat;
}

.c-title {
  font-family: $font-kiwi;
  color: $primary;
  font-size: 32px;
  letter-spacing: 0.08em;
  @include mq-down(lg) {
    font-size: 24px;
  }
  &--white {
    color: $white;
  }
  &--ub {
    padding-bottom: 30px;
    position: relative;
  @include mq-down(md) {
    padding-bottom: 24px;
  }
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      max-width: 507px;
      height: 4px;
      background-color: $primary;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }
  }
}

.c-btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1;
  color: $text-color;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 16px;
  transition: all ease-in .3s;
  a {
    color: inherit;
    display: block;
  }
  &__contact {
    background: $primary;
    color: $white;
    border-radius: 50px;
    padding: 28px;
    width: 100%;
    max-width: 393px;
    &:hover {
      background: darken($primary, 20%);
    }
    &--white {
      background: $white;
      color: $primary;
      &:hover {
        background: darken($white, 20%);
      }
    }
  }
}
