

.pc {
    display: none;
    @include mq-up(lg) {
        display: block;
    }
}

.sp {
    @include mq-up(lg) {
        display: none;
    }
}

.main {
    max-width: 100%;
    overflow: hidden;
}

.brand-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 8px;
    padding-bottom: 8px;
    

    @include mq-up(md) {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .brandhing-img {
        width: 230px;

        @include mq-up(sm) {
            width: 232px;
        }

        @include mq-up(lg) {
            width: 232px;
        }

        @include mq-up(xl) {
            width: 274px;
        }
    }

    .nav {
        display: none;

        @include mq-up(lg) {
            width: 870px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
        }
    }

    .sp.nav {
        display: block;

        @include mq-up(lg) {
            display: none;
        }

        button#toggle {

        }

        nav#js-nav {
        }
    }
}

.head-insta {
    margin-left: 8px;
    display: block;
    width: 40px;
    height: 40px;
    background-image: url(../../image/icon/insta.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    opacity: 1;

    &:hover {
        background-image: url(../../image/icon/insta-orange.svg);
        opacity: 1;
    }
}

.nav.pc {

    .menu-item{

        a {
            display: block;
            overflow: hidden;
            background-repeat: no-repeat;
            line-height: 5;
            height: 16px;
            background-size: contain;
        }

        &.home {

            a {
                background-image: url(../../image/nav/nav0.svg);
                width: 62px;

                &:hover {
                    background-image: url(../../image/nav/nav0-h.svg);
                }
            }
        }

        &.about {

            a {
                background-image: url(../../image/nav/nav1.svg);
                width: 88px;

                &:hover {
                    background-image: url(../../image/nav/nav1-h.svg);
                }
            }
        }

        &.toriatsukai {
            a {
                background-image: url(../../image/nav/nav2.svg);
                width: 156px;

                &:hover {
                    background-image: url(../../image/nav/nav2-h.svg);
                }
            }
        }

        &.company {
            a {
                background-image: url(../../image/nav/nav3.svg);
                width: 85px;

                &:hover {
                    background-image: url(../../image/nav/nav3-h.svg);
                }
            }
        }

        &.recruit {
            a {
                background-image: url(../../image/nav/nav4.svg);
                width: 85px;

                &:hover {
                    background-image: url(../../image/nav/nav4-h.svg);
                }
            }
        }

        &.contact {
            a {
                background-image: url(../../image/nav/nav5.svg);
                width: 84px;

                &:hover {
                    background-image: url(../../image/nav/nav5-h.svg);
                }
            }
        }
        &.catalog {
            a {
                background-image: url(../../image/nav/nav6.svg);
                width: 135px;
                height: 45px;

                &:hover {
                    background-image: url(../../image/nav/nav6-h.svg);
                }
            }
        }
    }
}

//SPハンバーガーとメニュー
.toggle {
  & {
    appearance: none;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 101;
    display: block;
    margin: 0;
    padding: 0;    
    width: 60px;
    height: 60px;
    border: none;
    //border-radius: 50%;
    background-color: #564E4B;
    transition: background-color 0.2s ease;
    cursor: pointer;
  }
 
  > span,
  &:before,
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    margin: -2px 0 0 -15px;
    width: 30px;
    height: 4px;
    border-radius: 2px;
  }

  > span {
    background-color: #fff;
    transition: opacity 0.3s ease;
  }

  &::before,
  &::after {
    content: "";
    background-color: #fff;
    transition: background-color 0.3s ease;
  }

  &::before {
    transform: translateY(-10px);
  }
 
  &::after {
    transform: translateY(10px);
  }

  // メニューを開いた時
  &.is-active {
    > span {
      opacity: 0;
    }
    &::before,
    &::after {
      background-color: white;
    }
    &::before {
      animation: 0.3s ease closeBar1 forwards;
    }
    &::after {
      animation: 0.3s ease closeBar2 forwards;
    }
  }
  // メニューを閉じる時
  &.-close {
    &::before {
      animation: 0.3s ease closeBar1Rev forwards;
    }
    &::after {
      animation: 0.3s ease closeBar2Rev forwards;
    }    
  }
}

@keyframes closeBar1 {
  0% { transform: translateY(-10px); }
  50% { transform: translateY(0) rotate(0); }
  100% { transform: translateY(0) rotate(45deg); }
}
@keyframes closeBar2 {
  0% { transform: translateY(10px); }
  50% { transform: translateY(0) rotate(0); }
  100% { transform: translateY(0) rotate(-45deg); }
}
@keyframes closeBar1Rev {
  0% { transform: translateY(0) rotate(45deg); }
  50% { transform: translateY(0) rotate(0); }
  100% { transform: translateY(-10px); }
}
@keyframes closeBar2Rev {
  0% { transform: translateY(0) rotate(-45deg); }
  50% { transform: translateY(0) rotate(0); }
  100% { transform: translateY(10px); }
}


.sp-nav {
    transform: translateX(100vw);
    transition: all .3s linear;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: #564E4B;
    color: black;
    opacity: 0;

    &.is-active {
        transform: translateX(0);
        opacity: 1;
    }

    .inner {
        width: 80%;
        max-width: 240px;

        .nav-btn-wrap {
            margin: 30px 0;
        }
    }

    .menu {

        li {
            border-bottom: 1px solid #fff;

            a {
                display: table;
                padding: 12px;

                img {
                    height: 16px;
                }
            }
        }
    }
  }
  
  .menu {

  }

//下層ページページタイトル画像の調整
.c-page-title-wrap {
    padding: 36px 0;

    @include mq-up(md) {
        padding: 44px 0;
     }

    &.or {
        background-color: $or;
    }

    &.gr {
        background-color: $gr;
    }

    .inner {
        .page-title {
            display: block;
            margin: 0 auto;
        }
    }
}

//下層ページのメインエリア空き調整
.under-page-pad {
    padding: 24px 0;

    @include mq-up(md) {
       padding: 48px 0;
    }
}

//webフォントを当てる
.font-maru {
    font-family: 'Kosugi Maru';
    letter-spacing: 0.2em;
}

.gyoukan-p {
    @include mq-up(md) {
        line-height: 3;
     }
}