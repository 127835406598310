/*
* General Settings
*/

/* kosugi-maru-regular - japanese */
@font-face {
	font-family: 'Kosugi Maru';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/kosugi-maru-v14-japanese-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/kosugi-maru-v14-japanese-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/kosugi-maru-v14-japanese-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/kosugi-maru-v14-japanese-regular.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/kosugi-maru-v14-japanese-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/kosugi-maru-v14-japanese-regular.svg#KosugiMaru') format('svg'); /* Legacy iOS */
}

body {
	-webkit-font-smoothing: antialiased;
	font-family: $font-root;
	line-height: 1.85;
	color: #514846;
}

a {
	transition: .3s ease-in-out;
	text-decoration: none;
  &:hover {
    text-decoration: none;
  }
	img{
		transition: .2s ease-in;
		&:hover {
			opacity: .6;
		}
	}
}
nav {
	ol,ul,li {
		list-style: none;
		padding: 0;
		margin: 0;
	}
}

img {
	max-width: 100%;
	height: auto;
}
