.page-title__lineup {
    width: 214px;

    @include mq-up(sm) {
        width: 274px;
    }
}

.page-title {
    &__contact {
        @include mq-up(sm) {
            width: 112px;
        }
    }

    &__company {
        @include mq-up(sm) {
            width: 192px;
        }
    }

    &__recruit {
        @include mq-up(sm) {
            width: 112x;
        }
    }


}