.policy {
    background-color: #F6F6F6;
    padding: 40px 0;

    @include mq-up(lg) {
        padding: 80px 0;
    }

    .inner {
        margin: 0 auto;

        @include mq-up(lg) {
            max-width: 800px;
        }
    }
}

.contact-text {
    text-align: center;

    @include mq-up(lg) {
        line-height: 3;
    }
}

.form-box {
    iframe {
        width: 100%;
    }
}