$white: #fff;
$primary: #28597F;
$base: $white;
$gray: #939393;
$dark-gray: #262318;
$text-color: #414141;
$or:#F0A000;
$gr:#6EB123;


$font-kiwi: 'Kiwi Maru', serif;
$font-noto: 'Noto Sans JP', sans-serif;
$font-root: $font-noto;
$header-breakpoint: 'xl';


// Grid columns
// ==========================================================================
// bootstrapのgridが変わりますよ！
$grid-columns: 12;
$grid-gutter-width: 40px;


// container-width
// ==========================================================================
// bootstrapのgridが変わりますよ！
$container-max-widths: (sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1200px) !default;