@keyframes infinity-scroll-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.p-calsel__wrap {
  display: flex;
  overflow: hidden;
}

.p-calsel__list {
  display: flex;
  list-style: none;
}

.p-calsel__list--left{
  animation :infinity-scroll-left 55s infinite linear 0.4s both;
}

.p-calsel__item {
  width: calc(100vw / 4);
}
.p-calsel__item > img{
  width: 100%;
}