// メディアクエリ
@function breakpoint-min2($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min and $min > 0, $min + .02, null);
}
@function breakpoint-max2($name, $breakpoints: $grid-breakpoints) {
  $max: map-get($breakpoints, $name);
  @return if($max != 0, $max, null);
}
@mixin mq-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min2($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin mq-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max2($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}


// ie11ハック用
@mixin ie11() {
  @at-root _:-ms-fullscreen,
    :root & {
    @content;
  }
}

// iphone5
@mixin iphone5() {
  @media screen and (max-width: 321px) {
    @content;
  }
}

// デバイス画像2倍
@mixin dppx() {
  @media (resolution: 2dppx) {
    @content;
  }
}

// pxをremに
@function rem($px) {
  @return ($px / 16) * 1rem;
}

//背景画像のimage-set設定
@mixin bg-image-set($url, $superratine: false) {
  background-image: url($url);
  background-image: getimageset($url, $superratine);
}
@function getimageset($url, $superratine: false) {
  @if $superratine {
    $x2: str-replace($url, '.png', '@2x.png');
    $x3: str-replace($url, '.png', '@3x.png');
    @return image-set($url 1x, $x2 2x, $x3 3x);
  } @else {
    $x2: str-replace($url, '.png', '@2x.png');
    @return image-set($url 1x, $x2 2x);
  }
}

//placeholder設定
@mixin placeholder($color) {
  &::placeholder {
    color: $color;
  }
  // IE
  &:-ms-input-placeholder {
    color: $color;
  }
  // Edge
  &::-ms-input-placeholder {
    color: $color;
  }
}

@mixin hover(){
  transition-property: opacity;
  transition-duration: .3s;
  &:hover{
    opacity: .7;
    text-decoration: none;
  }
}

@mixin fade() {
	opacity: 0;
  transition: all .5s ease-in;
  &.a-active {
    opacity: 1!important;
    transform: translate(0,0) !important;
  }
}


// ========================
// hover - action
// ========================
// usang
// @include hover-underline('fade', 'top', 4px, #aaa);

// $typeでfadeを指定した場合はtopかbottomを、
// $typeでslideを指定した場合はleft-right, left-in-right, right-left, right-in-left, center

@mixin hover-underline($type: fade, $dir: null, $weight: 2px, $color: #000) {
  @if $dir == null {
    @if $type == fade {
      $dir: "top";
    } @else if $type == slide {
      $dir: "center";
    }
  }

  position: relative;
  display: inline-block;
  text-decoration: none;
  &::after {
    position: absolute;
    left: 0;
    content: "";
    width: 100%;
    height: $weight;
    background: $color;
    @if $type == fade {
      transition: 0.3s;
      opacity: 0;
      visibility: hidden;
      @if $dir == bottom {
        bottom: $weight * -4;
      } @else {
        bottom: $weight;
      }
    } @else if $type == slide {
      bottom: $weight * -2;
      transform: scale(0, 1);
      transition: transform 0.3s;
      @if $dir == left-right or $dir == right-in-left {
        transform-origin: left top;
      } @else if $dir == right-left or $dir == left-in-right {
        transform-origin: right top;
      } @else {
        transform-origin: center top;
      }
    }
  }
  &:hover::after {
    @if $type == fade {
      bottom: $weight * -2;
      opacity: 1;
      visibility: visible;
    } @else if $type == slide {
      @if $dir == left-in-right {
        transform-origin: left top;
      } @else if $dir == right-in-left {
        transform-origin: right top;
      }
      transform: scale(1, 1);
    }
  }
}

// ========================
// ICONS
// ========================
@mixin icon-size($size) {
  width: #{$size}px;
  height: #{$size}px;
}
