.lineup-nav-pc,
.lineup-nav-sp  {
    list-style: none;
    padding: 0;
    margin: 0 0 1em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.lineup-nav-pc {

    li {
        margin-bottom: 4px;
        width: 16.4%;

        a {

        }
    }
}

.lineup-nav-sp {

    li {
        margin-bottom: 4px;
        width: 24.4%;

        a {
            
            
        }

        img {
            width: 100%;
        }
    }
}

.lineup-box {
    margin: 2em 0;

    .cat-title {
        background-color: #f1ebe0;
        display: flex;
        align-items: center;
        padding-left: 24px;
        height: 72px;
        margin: 0 -12px 12px;

        @include mq-up(md) {
            height: 88px;
            padding-left: 24px;
            margin: 0;
        }

        img {
            @include mq-up(md) {

            }
        }
    }
}

.lineup-company {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    li {
        width: 100%;
        padding: 4px 1em;

        &:nth-child(2n - 1) {
            background-color: #EAF0E0;
        }

        &:nth-child(2n) {
            background-color: #fff;
        }

        @include mq-up(md) {

            width: 50%;

            &:nth-child(4n - 3) {
                background-color: #EAF0E0;
            }
    
            &:nth-child(4n - 2) {
                background-color: #fff;
            }

            &:nth-child(4n - 1) {
                background-color: #fff;
            }

            &:nth-child(4n) {
                background-color: #EAF0E0;
            }
        }
    }
}

.lineup-totop {
    display: table;
    margin-left: auto;
    margin-top: 1em;

    img {
        width: 90px;

        @include mq-up(md) {
            width: 116px;
        }
    }
}