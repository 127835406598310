@import "global/_mixins.scss";
@import "global/_variables.scss";
@import "./bootstrap/bootstrap";
@import "foundation/_common.scss";
@import "foundation/_general.scss";
@import "layout/_footer.scss";
@import "layout/_l-header.scss";
@import "object/_common.scss";
@import "object/_component.scss";
@import "object/_contact.scss";
@import "object/_frontpage.scss";
@import "object/_lineup.scss";
@import "object/_p_pagetitle.scss";
@import "object/_p-calsel.scss";
@import "object/_p-scrollin.scss";
@import "object/_utility.scss";
@import "company/_company.scss";
@import "recruit/_recruit.scss";

//ここにスタイルを記述

header {
    display: flex;
    width: 100%;
    height: 100px;
    background-color: #fff;
    align-items: center;
  }
   
  .title {
    margin-right: auto;
  }
   
  .menu-item {
    list-style: none;
    display: inline-block;
    padding: 6px;
    vertical-align: top;
    margin-bottom: -12px;
    a{
        color: #000;
    }
  }

  ul .menu-group {
    list-style: none;
  }

.logo {
    width: 40%;
}

.top-img {
    background-image: url(../img/top.jpg);
}

.top-img {
    width: 100%;
    height: 700px;
    display: block;
    background-size: cover;
    background-position: center center;
    margin-top: -100px;
}

.tel-form {
    display: flex;
    width: 40%;
}

.tel {
    width: 40%;
    right: -128px;
    position: relative;
    bottom: -550px;
}

.form {
    width: 20%;
    right: -148px;
    position: relative;
    bottom: -548px;
}

.front-section {
    padding: 64px 0;

    @include mq-up(sm) {
        width: 128px 0;
    }

    .btn {
        font-size: 1.2em;
        font-weight: bold;
    }
}

#kishi-1 {
    background-color: #FBF3E7;

    .logo-img {
        text-align: center;
        margin-bottom: 30px;
        
        img {
            width: 272px;

            @include mq-up(sm) {
                width: 476px;
            }
        }
    }
}

p.front-sec-cont {

    @include mq-up(sm) {
        text-align: center;
        line-height: 52px;
    }

    &.sp-center {
        text-align: center;
    }
}

#kishi-2 {
    background-color: #F2B468;
    color: #fff;
    padding: 0 0 100px 0;

    .pet-img {
        width: 100%;
        //right: -360px;
        position: relative;
        bottom: 0px;
        top: -88px;
        margin-bottom: -60px;

        img {
            display: block;
            margin: 0 auto;
            transform-origin: center;
            transform: translateX(-28vw);
            height: 120px;

            @include mq-up(sm) {
                height: 146px;
            }
        }
    }

    .logo-img {
        text-align: center;
        margin-bottom: 30px;
        
        img {
            width: 252px;

            @include mq-up(sm) {
                width: 504px;
            }
        }
    }
}



#kishi-3 {
    background-color: #fff;
    padding: 100px 0 100px 0;

    .logo-img {
        text-align: center;
        margin-bottom: 30px;
        
        img {
            width: 350px;

            @include mq-up(md) {
                width: 750px;
            }
        }
    }

    .img-center {

        img {
            display: block;
            margin: 0 auto;
        }
    }

}


.logo-img2 {
    text-align: center;
    margin-bottom: 30px;
    img {
        width: 60%;
    }
}

#kishi-4 {
    //display: flex;
    background-color: #6EB123;
    position: relative;

    .deco-img {
        background-image: url(../../img/kishi-7.png);
        width: 100%;
        height: 0;
        padding-bottom: 50%;
        background-size: cover;

        @include mq-up(lg) {
            width: 50%;
            position: absolute;
            left: 50%;
            background-size: cover;
            height: 100%;
            padding-bottom: 0;
        }
    }

    .inner {
        color: #fff;
        padding: 44px 0;

        @include mq-up(lg) {
            width: 50%;
            padding: 74px 0;
        }
    }

    .btn {
        margin: 0 auto;
        display: table;
        font-size: 1.2em;
        font-weight: bold;
    }
}

#kishi-5 {
    background-color: #fff;

    .logo-img {
        text-align: center;
        margin-bottom: 30px;
        
        img {
            width: 88px;

            @include mq-up(md) {
                width: 112px;
            }
        }
    }

    .btn {
        margin: 0 auto;
        display: table;
        font-size: 1.2em;
        font-weight: bold;
        position: relative;
        z-index: 2;
    }


}

.logo-img3 {
    text-align: center;
    margin-bottom: 30px;
    img {
        width: 10%;
    }
}

.btn--orange,
a.btn--orange {
  color: #fff;
  background-color: #F1A100;
  border: 1px solid #F1A100;
  text-align: center;
  margin: 0px auto;
}
.btn--orange:hover,
a.btn--orange:hover {
  color: #fff;
  background: #f56500;
}

a.btn--radius {
   border-radius: 30px;
   padding: 5px 30px 5px 30px;
}

.btn--green,
a.btn--green {
  color: #fff;
  background-color: #6FB62C;
  border: 1px solid #6FB62C;
  text-align: center;
  margin: 0px auto;
}
.btn--green:hover,
a.btn--green:hover {
  color: #fff;
  background: #599a1b;
}

.pet-img2 {
    width: 100%;
    //right: -890px;
    position: relative;
    bottom: 0px;

    @include mq-up(lg) {
        top: -38px;
        margin-bottom: -60px;
    }

    img {
        width: 98px;
        display: block;
        margin: 0 auto;
        transform-origin: center;
        transform: translateX(33vw);

        @include mq-up(md) {
            width: 125px;
        }
    }
}

.right-b2 {
    
    padding: 100px 0 100px 0;
    
    p{
        text-align: center;
        line-height: 52px;
        color: #fff;
    }
}

#kishi-6 {
    background-color: #f1a100;
    color: #fff;
    position: relative;

    .inner {
        padding: 40px;
    }

    .map-enbed {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 86%;

        iframe {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    .logo-img {
        img {
            margin-bottom: 12px;
        }
    }

    @media(min-width: 1200px) {

        .inner {
            width: 400px;
            padding: 50px;
            height: 300px;
        }

        .map-enbed {
            position: absolute;
            width: calc((100vw - 1200px) / 2 + 780px );
            right: 0;
            top: 0;
            height: 100%;
            padding-bottom: 0;

            iframe {

            }
        }
    }
}

#kishi-7 {
    
    .container {
        position: relative;
    }

    .logo-img {
        text-align: center;
        margin-bottom: 30px;
        
        img {
            width: 88px;

            @include mq-up(md) {
                width: 112px;
            }
        }
    }

    .insta-aria {
        margin-bottom: 20px;

        a {
            display: table;
            margin: 0 auto;

            img {
                display: block;
                margin: 0 auto;
                width: 118px;

                @include mq-up(md) {
                    width: 150px;
                }
            }
        }
    }

    .pet-img3 {
        width: 100%;
        position: relative;
        bottom: 0px;

        img {
            width: 227px;
            display: block;
            margin: 0 auto;

            @include mq-up(md) {
                width: 290px;
                position: absolute;
                left: 0;
                bottom: -50px;
            }
        }
    }
}

.logo-img4 {
    text-align: center;
    margin-bottom: 30px;
    img {
        width: 30%;
    }
}

#kishi-8 {
    background: #F2F2F2;

    .logo-img {
        text-align: center;
        margin-bottom: 30px;
        
        img {
            width: 88px;
            display: block;
            margin: 0 auto;

            @include mq-up(md) {
                width: 112px;
            }
        }
    }

    .inner {
        margin: 0 auto;
        background-color: #fff;
        padding: 16px 40px;

        @include mq-up(md) {
            max-width: 600px;
            padding: 40px 16px;
        }

        a,
        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .contact-logo {
        width: 256px;
        display: block;
        margin-bottom: 20px;

        @include mq-up(md) {
            width: 330px;
            margin-bottom: 40px;
        }
    }
    .contact-mail {
        width: 116px;
        display: block;
        margin-bottom: 20px;
    }
    .contact-tel {
        width: 224px;
        display: block;
        margin-bottom: 20px;

        @include mq-up(md) {
            width: 300px;
        }
    }
    .contact-time {
        width: 226px;
        display: block;

        @include mq-up(md) {
            width: 300px;
        }
    }


}

/* ページトップボタン */
#page-top a {
    bottom: 0;
    color: #fff;
    padding: 20px;
    position: fixed;
    right: 0;
  }
  /* レイアウトのためのcss */
  a {
    text-decoration: none;
  }
  
  #page-top {
    bottom: 0;
    position: fixed;
    right: 0;
  }
  #page-top a {
    border-radius: 50%;
    height: 70px;
    padding: 0;
    text-align: center;
    transition: opacity .5s;
    width: 70px;
  }
  #page-top a:hover {
    opacity: 0.8;
    transition: opacity .5s;
  }
  #page-top a img {
    width: 100px;
  }

  .small-text {
    font-size: 12px;
  }