#company {
  margin-top: 5rem;
  margin-bottom: 5rem;
  position: relative;

  h4 {
    margin: 0;
    font-size: 24px;
    border-bottom: solid 2px #6fb62c;
    padding-bottom: 5px;
    margin-bottom: 15px;
    text-align: left;
    font-weight: bold;
    color: #6fb62c;
    width: 70%;
    margin: 0px auto;
    margin-top: 5rem;
  }

  h3 {
    margin: 0 auto;
    width: 70%;
    font-size: 24px;
    border-bottom: solid 2px #6fb62c;
    padding-bottom: 5px;
    margin-bottom: 15px;
    text-align: center;
    font-weight: bold;
    color: #6fb62c;
  }

  h2 {
    font-size: 22px;
    text-align: center;
    margin-top: 5rem;
    font-weight: bold;
  }

  h1 {
    font-size: 24px;
    text-align: center;
    font-weight: bold;
  }

  p {
    line-height: 52px;
  }
}

.img-b {
  margin-top: 5rem;
  margin-bottom: 5rem;
  text-align: center;

  img {
    width: 50%;
  }
}

/* table01 */

#table01 {
  margin: 0px auto;
  width: 70%;
}

#table01 tr {
  border-bottom: 1px solid #b5b1b1;
}

#table01 th,
#table01 td {
  padding: 24px 0;
  border: none;
}

#table01 th {
  width: 30%;
}

.daihyo-area {
  justify-content: center;
  align-items: center;
}

.photo-area {
  margin-right: 50px;
}

.photo-area2 {
  position: absolute;
  bottom: -60px;
  right: 130px;
  img {
    width: 242px;
  }
}

.photo-area3 img {
  display: none;
}

.photo-area4 {
  margin-right: 50px;
}

.daihyo-area2 {
  display: none !important;
}

.daihyo-area {
  display: flex !important;
}

.text-area {
  display: block;
}

/* sp */
@include mq-down(xl) {
  .container {
    width: 100% !important;
  }

  #company {
    h1 {
      text-align: right !important;
    }

    h2 {
      text-align: right !important;
    }

    h3 {
      text-align: left;
      width: 100%;
    }

    h4 {
      width: 100%;
    }

    p {
      font-size: 13px;
      text-align: left;
      line-height: 32px !important;
    }
  }

  .img-b {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .img-b img {
    width: 100%;
  }

  #table01 {
    width: 100%;
    font-size: 15px !important;
  }

  #table01 th,
  #table01 td {
    width: 100%;
    display: block;
  }

  #table01 th {
    width: 100%;
  }

  #table01 td {
    padding-top: 0;
  }

  .photo-area {
    display: flex;
    margin-right: 0px;
  }

  .photo-area img {
    width: 50%;
  }

  .photo-area4 {
    display: flex;
    margin-right: 0px;
  }

  .photo-area4 img {
    width: 50%;
  }

  .syomei {
    margin-left: 20px !important;
  }

  .syomei span {
    display: inline-block !important;
    font-size: 13px;
  }

  .syomei .katagaki-neme {
    font-size: 17px !important;
  }

  .syomei,
  .katagaki {
    font-size: 13px;
  }

  .text-area {
    margin-top: 30px;
    display: none;
  }

  .daihyo-text {
    font-size: 13px;
  }

  .photo-area3 {
    background-image: url(../image/company/company-daihyo3.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    padding-top: 74.5%;
    margin: -140px auto -60px;
  }

  .photo-area4 img {
    display: block !important;
    width: 50%;
  }

  .photo-area {
    display: none !important;
  }

  .photo-area2 img {
    display: none !important;
  }

  .daihyo-area2 {
    display: flex !important;
  }

  .daihyo-area {
    display: none !important;
  }
}

.company-midashi__1 {
  width: 250px;
  margin: 2em 0;
  display: block;

  @include mq-up(xl) {
    width: 523px;
    margin: 4em auto;
  }
}

.syomei {
  margin-top: 3em;
  width: 220px;
  margin-left: auto;
  text-align: center;

  @include mq-up(xl) {
    text-align: center;
    margin-left: inherit;
    width: auto;
  }

  span {
    display: block;
    font-weight: bold;
    line-height: 1.6;
  }

  .katgaki {
    font-size: 14px;

    @include mq-up(xl) {
      font-size: 20px;
    }
  }

  .katagaki-neme {
    font-size: 20px;

    @include mq-up(xl) {
      font-size: 22px;
    }
  }
}
